<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-tabs
          align="center"
          @activate-tab="tabChanged"
        >
          <b-tab
            title="Daily"
            active
          />
          <b-tab title="Monthly" />
          <b-tab title="Yearly" />
        </b-tabs>
        <water-source-wise-consumption-actions
          @generate="generateReport"
          @open-pdf="openPDF"
          @download-excel="downloadExcel"
        />
      </b-card-body>
    </b-card>
    <div>
      <report-title :card="titleCard" />
      <water-source-wise-consumption-chart />
      <water-source-wise-consumption-table ref="refTableCard" />
      <busy-modal :card="busyCard" />
    </div>
  </div>
</template>

<script>
import BusyModal from '@leecom/busy-modal/BusyModal.vue'
import ReportTitle from '@leecom/report-title/ReportTitle.vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import {
  BTabs, BTab, BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import CardPlaceholder from '@leecom/card-placeholder/CardPlaceholder.vue'
import WaterSourceWiseConsumptionChart from './chart/WaterSourceWiseConsumptionChart.vue'
import WaterSourceWiseConsumptionTable from './table/WaterSourceWiseConsumptionTable.vue'
import WaterSourceConsumptionStoreModule from './WaterSourceConsumptionStoreModule'
import useFetchData from './useFetchData'
import WaterSourceWiseConsumptionActions from './WaterSourceWiseConsumptionActions.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BTabs,
    BTab,
    BCardHeader,

    CardPlaceholder,
    WaterSourceWiseConsumptionActions,
    WaterSourceWiseConsumptionTable,
    ReportTitle,
    BusyModal,
    WaterSourceWiseConsumptionChart,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-reports-consumption-water-source-wise'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(
        STORE_MODULE_NAME,
        WaterSourceConsumptionStoreModule,
      )
    }
    onUnmounted(() => {
      store.dispatch(`${STORE_MODULE_NAME}/resetData`)
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const tabChanged = event => {
      store.dispatch(`${STORE_MODULE_NAME}/resetData`)
      let mode = 'day'
      if (event == 0) {
        mode = 'day'
      } else if (event == 1) {
        mode = 'month'
      } else if (event == 2) {
        mode = 'year'
      }

      store.dispatch(`${STORE_MODULE_NAME}/setMode`, mode)
    }

    // reset state when loading page
    tabChanged(0)

    const {
      generateReport,
      openPDF,
      downloadExcel,

      titleCard,
      busyCard,

      refTableCard,
    } = useFetchData()

    return {
      tabChanged,

      generateReport,
      openPDF,
      downloadExcel,

      titleCard,
      busyCard,

      refTableCard,
    }
  },
}
</script>
